import { Box, Button, Grid, Stack, styled, TextField, Typography } from "@mui/material";
import { ReactNode } from "react";
import { FlexBox } from "./Layout";
import { LoadingButton } from "@mui/lab";
import theme from "./theme/theme";
import { mobileScreen } from "../utils/breakpoints";
import { PopupContainer } from "../components/layout/popups/Popup.styles";

export const FormBox = styled(Stack)`
  //width: 91%;
  max-width: 145rem;
  height: 100%;
  background-color: ${(props: { empty?: string | undefined }) => (props.empty ? "rgba(255, 255, 255, 0.59)" : "#FFFFFF")};
  padding: 6.2rem 8.4rem;
  border-radius: 1.6rem;
  box-shadow: 0 1.5rem 1.8rem 0 rgb(28 41 90 / 14%);

  ${theme.breakpoints.down("xl")} {
    padding: 6rem 6rem;
  }

  ${theme.breakpoints.down("lg")} {
    &[empty="empty"] {
      display: none;
    }
  }

  ${theme.breakpoints.down("mobL")} {
    width: 100vw;
    padding: 0 3rem 5rem;
    box-shadow: none;

    .MuiFormHelperText-root.Mui-error {
      font-size: 1.4rem;
      font-weight: 500;
    }
  }
`;

export const GeneralSettingsBox = styled(Box)`
  margin: 8.2rem auto 0;
  width: 90%;
  max-width: 145rem;

  ${mobileScreen} {
    margin-top: 0;
    width: 100%;

    form {
      background-color: #fff;
    }
  }
`;

export const SupportTitle = styled(Typography)`
  display: inline-flex;
  align-items: flex-start;
  background-image: linear-gradient(206deg, #0080ff 0%, #242f57 100%);
  border-radius: 7px;
  font-family: Hellix, sans-serif;
  font-size: 2.6rem;
  color: #ffffff;
  font-weight: 500;
  line-height: 1.38;
  letter-spacing: 0.5px;
  margin: 0.5rem 0 4.6rem;
  padding: 2rem 7.6rem;
  svg {
    margin-right: 1.5rem;
  }
`;

export const SettingsTitle = ({ children }: { children: ReactNode }) => {
  const StyledTypography = styled(Typography)`
    ${theme.breakpoints.down("mobL")} {
      font-family: "Hellix", sans-serif;
      font-size: 2.57rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.81;
      text-transform: none;
      letter-spacing: 0.29px;
      color: #242f57;
      opacity: 0.81;
    }
  `;
  return (
    <StyledTypography variant={"h3"} marginBottom={"4.7rem"}>
      {children}
    </StyledTypography>
  );
};

export const FormInput = styled(TextField)`
  width: 100%;
  margin: 0.4rem 0 2.4rem;
  background-color: unset;

  .MuiOutlinedInput-root {
    background-color: #fcfafa;
    padding-right: 0.35rem;
  }

  .MuiOutlinedInput-input {
    background-color: #fcfafa;
    border-radius: 1rem;
    -webkit-box-shadow: 0 0 0 1000px #fcfafa inset !important;
  }

  .MuiInputBase-inputMultiline {
    padding: 0;
  }
  .MuiFormHelperText-root {
    font-size: 1rem;
  }

  ${theme.breakpoints.down("mobL")} {
    .MuiInputBase-root {
      border: solid 2px #97a0c3;
    }
    .Mui-error {
      border-color: #d32f2f;
      p {
        color: #d32f2f;
        animation: pulse 1s infinite;
      }
    }

    @keyframes pulse {
      0% {
        scale: 1;
        transform: translateY(1.3rem);
      }
      50% {
        transform: translateY(1.3rem) scale(1.1);
      }
      100% {
        transform: translateY(1.3rem);
      }
    }

    .MuiOutlinedInput-input {
      padding: 1.5rem;
      font-size: 1.9rem;
    }
    .MuiInputBase-inputMultiline {
      padding: 0 0.5rem;
    }
    fieldset {
      border: none;
    }

    .MuiInputBase-multiline {
      p {
        margin-top: -2rem;
      }
    }
  }

  &.login {
    .MuiInputBase-input {
      padding-left: 0.3rem;

      &::placeholder {
        color: #385261;
      }
    }
  }
`;


export const FormInputName = ({children, className}: { children: ReactNode, className?: string }) => {
  const styles = {
    '& span': {
      textDecoration: 'underline',
      marginLeft: '0.5rem',
      cursor: 'pointer',
    },
    '&.signup': {
      fontSize: '1rem',
      lineHeight: 1.44,
      letterSpacing: '0.1px',
      color: '#385261',
      maxWidth: '35rem',
    },
    '&.error': {
      marginTop: '1rem',
      color: theme.palette.error.main,
    },
    '&.bold': {
      fontWeight: 600,
      fontSize: '1.7rem',
      letterSpacing: '0.18px',
      color: '#385261',
    }
  }
  return <Typography variant={'subtitle2'} sx={styles} className={className}>{children}</Typography>
}


export const FormRequiredIcon = styled(Typography)`
  font-size: 3.4rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  color: #97a0c3;
  margin-right: 1rem;
  transform: translateY(1.3rem);
  height: 100%;
  align-self: flex-start;
`;

export const SupportFormGrid = styled(Grid)`
  height: max-content;
  align-items: flex-start;
  justify-content: space-between;
`;

export const SupportFormGridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;


export const ButtonsBox = styled(FlexBox)`
  justify-content: space-between;
  //margin-bottom: 5.8rem;
  padding-top: 0.98rem;

  button:nth-of-type(2) {
    border-radius: 2.32rem;
    box-shadow: unset;
    padding: 1rem 6.4rem;
    font-size: 1.52rem;
    font-weight: 600;
    letter-spacing: 0.17px;
    color: #04b78a;
    background-color: #fff;
    border-color: #04b78a;
    margin-left: 1.7rem;
  }
  ${theme.breakpoints.down("mobL")} {
    flex-direction: column;
    margin-bottom: 0;

    button:nth-of-type(2) {
      margin: 3rem 0;
      width: 100%;
      border: none;
      text-transform: none;
      text-decoration: underline;
      font-weight: normal;
      font-size: 2.3rem;
    }
  }
`;

export const LoaderButton = styled(LoadingButton)`
  border-radius: 2.32rem;
  box-shadow: unset;
  padding: 1rem 6.4rem;
  font-size: 15.2px;
  font-weight: 600;
  letter-spacing: 0.17px;

  &:hover {
    background-color: ${theme.palette.success.main};
  }

  ${theme.breakpoints.down("mobL")} {
    width: 90%;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 3.3rem;

    font-size: 2.34rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.26px;
    text-align: center;
    color: #fff;
  }
`;

export const SupportFootnote = styled(Typography)`
  opacity: 0.53;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.11px;
  color: #4a5767;
`;

export const SupportImageBox = styled(FlexBox)`
  position: relative;
  border: 0.7px solid #9fb0c6;
  border-radius: 7px;
  padding: 0.5rem;
  width: 23%;
  aspect-ratio: 1/1;
  max-width: 11rem;
  max-height: 11rem;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  button {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    top: 0.2rem;
    right: 0.2rem;
    cursor: pointer;
    padding: 0.5rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const SupportPopupContainer = styled(PopupContainer)`
  max-width: 95%;
  margin: 0 auto;
  .MuiDialog-paper {
    padding: 6rem 3rem !important;
  }
`;
export const SupportThankYouStack = styled(Stack)`
  align-items: center;
`;
export const SupportThankYouTitle = styled(Typography)`
  font-size: 4.4rem;
  font-weight: 500;
  line-height: 0.82;
  letter-spacing: 0.85px;
  color: #242f57;
  margin: 3.2rem 0 3.9rem;
`;

export const SupportThankYouText = styled(Typography)`
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 1.38;
  letter-spacing: 0.5px;
  text-align: center;
  color: #242f57;
  margin-bottom: 6rem;
  ${theme.breakpoints.down("mobL")} {
    margin-bottom: 0;
  }
`;

export const ButtonChangeWallet = styled(Button)`
  // background-color: ${theme.palette.success.main};
  width: fit-content;
  flex-shrink: 0;
  //border: unset;
  font-size: 1.2rem;
  border-radius: 7px;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;

  p {
    color: ${theme.palette.success.main};
    text-transform: capitalize;
    font-size: 1.54rem;
    font-weight: 500;
    letter-spacing: 0.17px;
    flex-shrink: 0;
  }

  span.MuiButton-startIcon {
    flex-shrink: 0;
    height: 2rem;
    padding: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  span.MuiTouchRipple-root {
    color: #594e4e;
  }
`;

export const ConnectWalletBlock = styled(Stack)`
  & > p:first-of-type {
    color: #000000;
    margin-bottom: 1.7rem;
  }

  a {
    margin-top: 1.7rem;
    text-decoration: underline;
    color: rgba(74, 87, 103, 0.52);
  }
  a:visited {
    color: rgba(74, 87, 103, 0.52);
  }
`;

export const NoNFTDetected = styled(Typography)`
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: #4a5767;
  text-transform: uppercase;
  margin-bottom: 2rem;
  svg {
    transform: translateY(30%);
  }
`;

export const FormAddButton = styled(Button)`
  border: none;
  width: fit-content;
  text-decoration: underline;
  font-weight: 500;

  &:hover {
    border: none;
  }

  ${theme.breakpoints.down("mobL")} {
    font-size: 1.6rem;
  }
`;

export const FormCompanyItem = styled(FlexBox)`
  justify-content: space-between;
  background-color: #fcfafa;
  padding: 3rem 7.5rem;
  margin: 0 -7.5rem 0.7rem;
  //margin-bottom: .7rem;

  ${theme.breakpoints.down("mobL")} {
    margin: 0 -3rem;
    padding: 3rem;
  }

  &.selected {
    opacity: 0.33;

    button:first-of-type {
      cursor: default;
    }
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.18px;
    color: #4a5767;
  }
  p {
    font-size: 1.4rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.16px;
    color: #4a5767;
    opacity: 0.3;
  }

  > div:last-of-type {
    column-gap: 1rem;
    button {
      border: none;
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);
      font-weight: 600;

      svg {
        height: 1.8rem;
      }

      &:first-of-type {
        color: #04b78a;
        svg {
          path {
            fill: #04b78a;
          }
        }
      }

      &:last-of-type {
        color: #e52626;

        svg {
          path {
            fill: #e52626;
            stroke: #e52626;
            stroke-width: 0.5px;
          }
        }
      }
    }
  }
`;
