import { HeaderContainer } from "../Header.styles";
import { CreateCampaignLink } from "./HeaderDesktop.styles";
import { Toolbar, Typography } from "@mui/material";
import { appLink } from "../../../../utils/constants";
import { FlexBox } from "../../../../styled/Layout";
import BalanceMenu from "./balance/BalanceMenu";
import UserMenu from "./user/UserMenu";
import { useHeaderData } from "../useHeaderData";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";

import {userSelector} from "../../../../redux/user/userSelector";
import {pathWithUTM} from "../../../../utils/pathWithUTM";

type TProps = {
  offset: string;
};

const HeaderDesktop = ({ offset }: TProps) => {
  const { isAuthenticated } = useAuth0();
  const { currency, balance, pages } = useHeaderData();
  // const { userData } = useSelector(userSelector);
  // const noWallet = !userData?.wallet_address || userData?.wallet_address === "" || !userData?.has_nft;
  const noWallet = false;

  return (
    <HeaderContainer elevation={0} sx={{ pl: offset }}>
      <Toolbar>
        <CreateCampaignLink href={pathWithUTM(appLink)} target={"app"} className={`${noWallet ? "disabled" : ""}`}>
          <Typography>+ CREATE NEW CAMPAIGN</Typography>
        </CreateCampaignLink>
        <FlexBox columnGap={"1.7rem"} sx={{ display: isAuthenticated ? "flex" : "none" }}>
          <BalanceMenu currency={currency} balance={balance} />
          <UserMenu pages={pages} />
        </FlexBox>
      </Toolbar>
    </HeaderContainer>
  );
};

export default HeaderDesktop;
