import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { usePathname } from "../../../../../utils/hooks/usePathname";

export const useHeaderMobileMenu = () => {
  const { pathname } = useLocation();
  const { isPPCPage, isDataminingPage, isPRPage } = usePathname();

  const accRef = useRef<HTMLDivElement>(null);
  const ppcRef = useRef<HTMLDivElement>(null);
  const prRef = useRef<HTMLDivElement>(null);
  const pixelRef = useRef<HTMLDivElement>(null);

  // Open the accordion for the current page
  useEffect(() => {
    if (!accRef.current) return;
    const accRoutes = ["/dashboard", "/settings", "/billing/details", "/billing/deposit_withdraw", "/billing/history", "/support"];
    if (accRoutes.includes(pathname)) {
      accRef?.current?.click();
    } else if (isPPCPage) {
      ppcRef?.current?.click();
    } else if (isDataminingPage) {
      pixelRef?.current?.click();
    } else if (isPRPage) {
      prRef?.current?.click();
    }
  }, [pathname, accRef]);

  const campaignTypes = [
    {
      title: "PPC Ads",
      ref: ppcRef,
      pages: [
        { title: "Overview", route: "/ppc/overview" },
        { title: "My campaigns", route: "/ppc/mycampaigns" },
      ],
    },
    {
      title: "PR Campaigns",
      ref: prRef,
      pages: [
        { title: "Overview", route: "/pr/overview" },
        { title: "My campaigns", route: "/pr/mycampaigns" },
      ],
    },
    {
      title: "Data Mining",
      ref: pixelRef,
      pages: [
        { title: "Overview", route: "/data_mining/overview" },
        { title: "Pixel", route: "/data_mining/pixel" },
      ],
    },
  ];

  return { accRef, ppcRef, prRef, pixelRef, campaignTypes };
};
