import { DEV_ONLY, isSampleData, STAGING, userId } from "../utils/constants";
import clientBuilder from "./clientBuilder";
import { store } from "../redux/store";
import { setUserDataAction, setUserField } from "../redux/user/userSlice";
import { AxiosResponse } from "axios";
import { User } from "../types/services/user/response/userData/userResponse";
import { BudgetInfoResponse } from "../types/services/user/response/checkPayment/budgetInfoResponse";
import { UpdateUserRequest } from "../types/services/user/request/updateUserRequest";
import { UpdateUserResponse } from "../types/services/user/response/updateUserResponse";
import { AvailableBalanceResponse } from "../types/services/user/response/availableBalanceResponse";
import { WithdrawRequest } from "../types/services/user/request/withdrawRequest";
import { WithdrawResponse } from "../types/services/user/response/withdrawResponse";
import { DepositResponse } from "../types/services/user/response/depositResponse";

const BASE_URL = `https://mvp-campaigns-${DEV_ONLY ? "develop-" : STAGING ? "staging-" : ""}dot-winky-apis.ew.r.appspot.com`;
// const BASE_URL = 'http://localhost:3003'

const userClient = clientBuilder(BASE_URL);

export const getUser = async (user_id?: string) => {
  try {
    const response: AxiosResponse<User> = await userClient.get(`/user/${isSampleData ? userId() : user_id ?? userId()}`);
    const dataUser = response?.data;
    // console.log(dataUser)
    if (dataUser) {
      const data = {
        first_name: dataUser?.personal_info?.first_name ?? "",
        last_name: dataUser?.personal_info?.last_name ?? "",
        email: dataUser?.personal_info?.mail_address,
        wallet_address: isSampleData ? "0xb8d3015d3edc2b8ec8f85c0703d46a4aa95ea9a1" : dataUser?.payment_info?.wallet_address || "",
        // has_nft: isSampleData ? true : !!dataUser?.payment_info?.has_nft,
        has_nft: true,
        companies: dataUser?.companies,
      };
      store.dispatch(setUserDataAction(data as any));
      store.dispatch(
        setUserField({
          budget_info: dataUser?.budget_info,
          billing_info: dataUser?.billing_info,
        })
      );
      return dataUser;
    }
  } catch (err) {
    console.log(err);
  }
};

export const checkHistory = async (user_id: string) => {
  // console.log(user_id)
  try {
    const response: AxiosResponse<BudgetInfoResponse> = await userClient.get(
      `check_payment/${isSampleData ? userId() : user_id ?? userId()}`
    );
    // if (response?.data) {
    // const balance = response?.data?.balance
    // if (balance && balance !== store.getState().user.budget_info?.balance) {
    //     store.dispatch(setUserField({budget_info: response.data.budget_info }))
    // }
    // }
    return response?.data?.billing_history;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserField = async (user_id: string, data: UpdateUserRequest) => {
  try {
    const response: AxiosResponse<UpdateUserResponse> = await userClient.patch(
      `/user/${isSampleData ? userId() : user_id ?? userId()}`,
      data
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAvailableBalance = async () => {
  try {
    const response: AxiosResponse<AvailableBalanceResponse> = await userClient.get(`/user_available/${userId()}`);
    if (response?.data) {
      store.dispatch(
        setUserField({
          available_balance: response.data.available_balance,
          user_balance: response.data.user_balance,
        })
      );
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const makeWithdraw = async (data: WithdrawRequest) => {
  try {
    const response: AxiosResponse<WithdrawResponse> = await userClient.post("Withdraw", data);
    return response?.data;
  } catch (error) {
    console.log(error);
    return {'status': false}
  }
};

export const makeDeposit = async (data: WithdrawRequest) => {
  try {
    const response: AxiosResponse<DepositResponse> = await userClient.post("Deposit", {...data, available: false});
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
