import { HeaderBalance, HeaderContainer, HeaderUserData } from "../Header.styles";
import { HeaderPlusButton, MobileMenuContainer, ThreeDotsHeader } from "./HeaderMobile.styles";
import HeaderMobileMenu from "./mobileMenu/HeaderMobileMenu";
import { appLink, websiteLink } from "../../../../utils/constants";
import { Avatar, Button, IconButton, Stack, Toolbar, Typography } from "@mui/material";
import { Logo } from "../../../../assets/svg/logo";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useHeaderData } from "../useHeaderData";
import { useHeaderMobile } from "./useHeaderMobile";
import {userSelector} from "../../../../redux/user/userSelector";
import {pathWithUTM} from "../../../../utils/pathWithUTM";

const HeaderMobile = () => {
  const { user } = useAuth0();
  const { userData } = useSelector(userSelector);
  const userName = userData.first_name.length || userData.last_name.length ? `${userData.first_name} ${userData.last_name}` : " ";
  const navigate = useNavigate();
  const { pages, currency, balance } = useHeaderData();
  const { mobileMenuOpen, toggleMobileMenu } = useHeaderMobile();

  return (
    <>
      <HeaderContainer elevation={0} className={mobileMenuOpen ? "mobOpen" : ""}>
        <Toolbar>
          {!mobileMenuOpen ? (
            <a href={pathWithUTM(websiteLink)}>
              <IconButton className={"mobileOnly logo"}>
                <Logo />
              </IconButton>
            </a>
          ) : (
            <HeaderUserData className={"mobileOnly"}>
              <Avatar src={user?.picture} />
              <Stack>
                <Typography variant="h4">{userName}</Typography>
                <Typography>Admin</Typography>
              </Stack>{" "}
            </HeaderUserData>
          )}
          {!mobileMenuOpen && (
            <a className={"mobileOnly"} href={pathWithUTM(appLink)} style={{ transform: "translateX(30%) scale(2)" }} target={"app"}>
              <HeaderPlusButton>+</HeaderPlusButton>
            </a>
          )}
          <ThreeDotsHeader open={mobileMenuOpen} handleOpen={toggleMobileMenu} />
        </Toolbar>
        {mobileMenuOpen && (
          <HeaderBalance className={"mobileOnly"}>
            <Stack>
              <Typography variant={"h5"}>Balance</Typography>
              <Typography>
                {currency}
                {balance}
              </Typography>
            </Stack>
            <Button
              startIcon={<span>+</span>}
              onClick={() => {
                navigate(pathWithUTM("/billing/deposit_withdraw"));
                toggleMobileMenu();
              }}
            >
              Add funds
            </Button>
          </HeaderBalance>
        )}
      </HeaderContainer>
      <MobileMenuContainer open={mobileMenuOpen} className={"mobileOnly"}>
        <HeaderMobileMenu pages={pages} toggleMobileMenu={toggleMobileMenu} />
      </MobileMenuContainer>
    </>
  );
};

export default HeaderMobile;
