//Core
import React from "react";
//Styles
import {CircularProgress, Typography} from "@mui/material";
import {LoginImgBox, LoginImgStack, LoginStack} from "./Login.styles";
//Hooks
import {useBreakpoints} from "../../../utils/hooks/useBreakpoints";
import {useLogin} from "./useLogin";
//Components
import {SignInSignUp} from "./signInSignUp/SignInSignUp";
//Data
import {
    changePageButtonText,
    changePageText,
    loginImage,
    loginImageMobile,
    TActiveLogin,
    TActivePassword
} from "./Login.data";
import {FlexSpaceBetween, ImgBox} from "../../../styled/Layout";
import {useAuth} from "../../layout/header/useAuth";
import {useNavigate} from "react-router-dom";
import {ForgotPassword} from "./forgotPassword/ForgotPassword";

const login: string[] = ['signIn', "signUp"]
const password: string[] = ['forgotPassword', 'requestSent']
const Login = () => {
    const {mobileLarge} = useBreakpoints();
    const {loading, isAuthenticated} = useAuth();
    const navigate = useNavigate();

    const {webAuth, activePage, handleActivePage, setActivePage} = useLogin();

    if (isAuthenticated) navigate('/dashboard')

    return (
        <>
            {loading && <CircularProgress/>}
            {!isAuthenticated && !loading &&
                <LoginStack>
                    {mobileLarge &&
                        <ImgBox>
                            <img src={loginImageMobile[activePage]} alt={activePage} width={500} height={180}/>
                        </ImgBox>
                    }
                    <FlexSpaceBetween sx={{minHeight: 'auto', maxHeight: '69rem'}}>
                        {login.includes(activePage) &&
                            <SignInSignUp webAuth={webAuth}
                                          activePage={activePage as TActiveLogin}
                                          changePage={setActivePage}
                            />
                        }
                        {password.includes(activePage) &&
                            <ForgotPassword webAuth={webAuth}
                                            activePage={activePage as TActivePassword}
                                            changePage={setActivePage}
                            />
                        }
                        {!mobileLarge &&
                            <LoginImgBox>
                                <img src={loginImage[activePage]} alt={activePage} width={259} height={692}/>
                                <LoginImgStack>
                                    <Typography>{changePageText[activePage]}</Typography>
                                    <Typography
                                        onClick={handleActivePage}>{changePageButtonText[activePage]}</Typography>
                                </LoginImgStack>
                            </LoginImgBox>
                        }
                    </FlexSpaceBetween>
                    {mobileLarge &&
                        <LoginImgStack>
                            <Typography>{changePageText[activePage]}</Typography>
                            <Typography onClick={handleActivePage}>{changePageButtonText[activePage]}</Typography>
                        </LoginImgStack>
                    }
                </LoginStack>
            }
        </>
    );
};

export default Login;
