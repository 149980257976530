import * as yup from "yup";
import {useCallback, useState} from "react";
import auth0, {ChangePasswordOptions} from "auth0-js";
import {TActivePage} from "../Login.data";
import {DATABASE_CONNECTION} from "../useLogin";

export type TResetFormData = {
    username: string;
}

export const validationSchema = yup.object({
    username: yup.string().email("Email not valid").required("Email is required"),
});

export const useForgotPassword = (webAuth: auth0.WebAuth, changePage: (key: TActivePage) => void) => {
    const formData: TResetFormData = {username: ''};
    const [email, setEmail] = useState<string>('');
    const [errorResponse, setErrorResponse] = useState<string | null>(null);

    const changePassword = useCallback((username: string) => {
        return new Promise((resolve, reject) => {
            webAuth.changePassword({
                connection: DATABASE_CONNECTION,
                email: username,
            } as ChangePasswordOptions, (error, result) => {
                if (error) {
                    reject(error);
                    setErrorResponse(error.description || error.error_description || error.error || 'something went wrong')
                    return;
                }
                resolve(result);
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webAuth]);

    const sendForm = async (values: TResetFormData) => {
        try {
            setEmail(values.username)
            await changePassword(values.username);
            changePage('requestSent');
        } catch (e) {
            console.log(e);
        }
    }

    return {
        email,
        formData,
        errorResponse,
        sendForm
    }
}