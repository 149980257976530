import {Box, Button, Stack, styled, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import theme from "../../../styled/theme/theme";
import {FlexSpaceBetween, ImgBox} from "../../../styled/Layout";

export const LoginStack = styled(Stack)`
  width: 95%;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0 11px 28px 0 rgba(0, 0, 0, 0.09);
  border-radius: 0.8rem;
  max-width: 85rem;
  margin: 10rem auto;
  overflow: hidden;

  ${theme.breakpoints.down("mobL")} {
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0;
    border-radius: 0;
  }  
`

export const LoginTitle = styled(Typography)`
  font-family: 'F37 Judge', sans-serif;
  font-size: 3.12rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  color: #385261;
  margin-bottom: 1.6rem;
  ${theme.breakpoints.down("mobL")} {
    text-align: center;
  }
`

export const LoginDescription = styled(Typography)`
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.16px;
  color: #385261;
  max-width: 30rem;
  margin-bottom: 4.3rem;
  ${theme.breakpoints.down("mobL")} {
    text-align: center;
  }
`

export const LoginFormStack = styled(Stack)`
  flex-grow: 1;
  padding: 8rem;
  width: 70%;
    ${theme.breakpoints.down("mobL")} {
        width: 100%;
      padding: 3rem;
    }
`

export const LoginButton = styled(LoadingButton)`
  border-radius: 8px;
  background-color: #195afe;
  box-shadow: none;
  padding: 0.6rem 0;
  width: 100%;
  margin-top: 4.55rem;
  font-size: 2rem;
  letter-spacing: 0.22px;
  color: #ffffff;
  text-transform: unset;
  &:hover {
    background-color: ${theme.palette.primary.main};
    box-shadow: none;
  }
`

export const OrBox = styled(FlexSpaceBetween)`
  margin: 2.9rem 0;
  align-items: center;
  & hr {
    width: 44%;
  }
  & p {
    font-size: 1.2rem;
    letter-spacing: 0.13px;
    color: #385261;
    margin: 0 1rem;
  }
`

export const LoginWithGoogleButton = styled(Button)`
  border-radius: 8px;
  color: #000000;
  text-transform: unset;
  & svg {
    width: 3.3rem;
  }
  &:hover {
    background-color: #FFFFFF;
  }
`

export const LoginImgBox = styled(Box)`
  width: 30%;
  max-height: 69rem;
  margin: -1px 0 0;
  position: relative;
  overflow: hidden;
`

export const LoginImgStack = styled(Stack)`
  position: absolute;
  left: 2rem;
  bottom: 2rem;
  align-items: flex-start;
  & p {
    font-size: 1.4rem;
    line-height: 1.31;
    letter-spacing: 0.16px;
  }
    & p:first-of-type {
      color: #3a3f43;
    }
  & p:last-of-type {
    font-weight: 600;
    color: #4f6bb1;
    cursor: pointer;
    text-decoration: underline;
  }
    ${theme.breakpoints.down("mobL")} {
      position: relative;
      align-items: center;
      margin: 1rem auto 3rem;
      left: 0;
      bottom: 0;
    }
`

export const LoginShowPasswordButton = styled(Button)`
  margin-left: auto;
  font-size: 1.08rem;
  letter-spacing: 0.12px;
  align-self: flex-end;
  color: #385261;
  text-transform: unset;
    & svg {
        width: 1.6rem;
        height: 1.6rem;
    }
`

