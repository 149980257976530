import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPixelData } from "../../../../services/pixelService";
import { BalanceResponse } from "../../../../types/services/pixel/response/balanceResponse";
import {userSelector} from "../../../../redux/user/userSelector";

export const useRewards = () => {
  const { id } = useSelector(userSelector);
  const [rewards, setRewards] = useState({
    total: 0,
    daily: 0,
  });

  useEffect(() => {
    const getData = async () => {
      const res = await getPixelData<BalanceResponse>(id, "balance");
      if (res) {
        setRewards({ total: Math.round(res?.total_rewards_balance ?? 0), daily: Math.round(res?.avg_daily_rewards ?? 0) });
      }
    };
    if (id) {
      getData();
    }
  }, [id]);

  return { rewards };
};
