import { Box, Dialog } from "@mui/material";
import { styled } from "@mui/system";
import { FlexBox } from "../../../styled/Layout";
import theme from "../../../styled/theme/theme";

export const PopupContainer = styled(Dialog)`
  .MuiDialog-container {
    /* margin: 0 auto; */

    .MuiDialog-paper {
      position: relative;

      max-width: 113rem;
      box-shadow: 0 10px 17px 0 rgba(0, 0, 0, 0.25);
      padding: 10rem 10.7rem 8.7rem 8.5rem;
      border-radius: 1.8rem;

      .close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        > svg {
          width: 31px;
          height: 31px;
        }
      }
      ${theme.breakpoints.down("mobL")} {
        margin: 0;
      }
    }
  }
`;

export const PopupContent = styled(FlexBox)`
  margin: 0 auto;

  svg {
    margin-right: 3.2rem;
    flex-shrink: 0;
  }

  h3 {
    font-size: 4.6rem;
    letter-spacing: 1.61px;
    color: #010a10;
    margin-bottom: 2.5rem;
    line-height: normal;
  }

  h4 {
    font-size: 2.2rem;
    font-weight: 600;

    line-height: 1.5;
    letter-spacing: -0.23px;
    color: #11354f;
  }

  p {
    font-size: 2rem;
    line-height: 1.65;
    letter-spacing: -0.21px;
    color: #11354f;
  }
`;

export const PopupButtons = styled(FlexBox)`
  margin-top: 6.6rem;
  justify-content: center;
  column-gap: 2.4rem;

  button {
    border-radius: 4rem;
    font-size: 2.2rem;
    font-weight: bold;
    letter-spacing: 0.24px;
    color: #fff;
    width: calc(50% - 1.2rem);
    padding: 2rem 0;
    text-transform: none;

    &:first-of-type {
      color: #fff;
      background-color: #010a10;

      &:disabled {
        background-color: rgba(1, 10, 16, 0.05);
        color: rgba(99, 110, 149, 0.3);
        border: none;
      }
    }

    &:last-of-type {
      color: #010a10;
      background-color: #fff;
      border: 1px solid #010a10;
    }
  }
`;

export const PopupInputContainer = styled(Box)`
  margin-top: 5.1rem;
  background-color: rgba(1, 10, 16, 0.05);
  border-radius: 1rem;
  padding: 3.7rem 3.1rem 4.4rem;

  > p {
    margin-bottom: 1.6rem;
  }

  .currency {
    font-size: 2.5rem;
    font-weight: 500;
    letter-spacing: 0.78px;
    color: #636e95;
    display: block;
    align-self: center;
    margin-right: 0.7rem;
  }

  .MuiInputBase-input {
    background-color: #fafcfe;
    width: 21rem;
  }
`;
