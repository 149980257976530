import { createSlice } from "@reduxjs/toolkit";
import { format, sub } from "date-fns";

const DATE_FORMAT = "dd/MM/yyyy"

type TInitialState = {
  dates: {
    start: string;
    end: string;
  };
  dates_available:{
    start: string;
    end: string;
  }
  status: {
    active: boolean;
    ended: boolean;
    suspended: boolean;
    deleted: boolean;
  };
};

const initialState: TInitialState = {
  dates: {
    start: format(sub(new Date(), { months: 1 }), DATE_FORMAT),
    end: format(new Date(), DATE_FORMAT),
  },
  dates_available: {
    start: format(sub(new Date(), { months: 1 }), DATE_FORMAT),
    end: format(new Date(), DATE_FORMAT),
  },
  status: {
    active: true,
    ended: true,
    suspended: true,
    deleted: true,
  },
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setDates: (state, { payload }) => {
      state.dates = { ...state.dates, ...payload };
    },
    setAvailableDates: (state, { payload }) => {
      state.dates_available = { ...state.dates_available, ...payload };
    },
    setStatus: (state, { payload }) => {
      state.status = { ...state.status, ...payload };
    },
  },
});

export const { setDates, setStatus, setAvailableDates } = filtersSlice.actions;

export default filtersSlice.reducer;
