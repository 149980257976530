import { CircularProgress } from "@mui/material";
import { Container } from "@mui/system";
import { useBreakpoints } from "../../../utils/hooks/useBreakpoints";
import { BlockContainer } from "../../../styled/Layout";

const Loading = () => {
  const { mobileLarge } = useBreakpoints();
  const mobileProps = mobileLarge
    ? { backgroundColor: "transparent !important", border: "none !important", boxShadow: "none !important" }
    : {};
  return (
    <Container sx={{ pt: "5rem" }}>
      <BlockContainer sx={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center", ...mobileProps }}>
        <CircularProgress />
      </BlockContainer>
    </Container>
  );
};

export default Loading;
