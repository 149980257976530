import { isSampleData, userId } from "../utils/constants";
import clientBuilder from "./clientBuilder";
import { AxiosResponse } from "axios";

const BASE_URL = `https://web3m-beta.com/rewards/api/`;

const pixelClient = clientBuilder(BASE_URL);

export const getPixelData = async <TResponse>(user_id: string, command: string): Promise<TResponse> => {
  const params = {
    user_id: isSampleData ? userId() : user_id,
    command,
  };
  const res: AxiosResponse<TResponse> = await pixelClient.get("", { params });
  return res.data;
};
