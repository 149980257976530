import { createTheme } from "@mui/material";
import "../../assets/fonts/fonts.module.css";
import version from "../../version.json";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      mobS: 330,
      mobM: 420,
      br480: 480,
      mobL: 500,
      sm: 600,
      br768: 768,
      md: 900,
      br1024: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  colors: {
    graphPurple: "#4540BB",
    graphBlue: "#1781C5",
    graphOrange: "#F2A626",
    graphGreen: "#15AA95",
    circlePink: "#EF2F6E",
    lineGraphBlue: "#8FC6FE",
    textColor: "#636E95",
  },
  palette: {
    primary: {
      light: "#2191FF", //main text
      main: "#0080FF", //main buttons and logo
      dark: "#182FCD", //buttons arrow in creatives
    },
    secondary: {
      light: "#FAFCFE", //inputs
      main: "#F4F7FC", //main background
      dark: "#D2D5E1", //input border
    },
    info: {
      main: "#D1DDED", //blocks background
      dark: "#667197", //info square
    },
    success: {
      main: "#04B78A", //day filter and expand
      contrastText: "#FFFFFF",
    },
  },
  typography: {
    fontSize: 14,
    fontWeightRegular: 400,
    fontWeightBold: 600,
    fontFamily: "'Hellix', sans-serif",

    h1: {
      fontSize: "2.7rem",
      fontFamily: "F37 Judge, sans-serif",
      fontWeight: "bold",
      lineHeight: 1.11,
      letterSpacing: '1.35px'
    },
    h2: {
      fontSize: "2.6rem",
      color: "#242F57",
      letterSpacing: "0.5px",
      lineHeight: "36px",
      fontWeight: 500,
    },
    h3: {
      fontFamily: "F37 Judge, sans-serif", //Block title
      fontSize: "2rem",
      color: "#242F57",
      letterSpacing: "1px",
      lineHeight: "30px",
      "@media (max-width: 500px)": {
        fontFamily: "Hellix-Medium, Hellix, sans-serif",
        textTransform: "uppercase",
        letterSpacing: "0.21px",
        fontSize: "1.8rem",
        color: "#9ca0ad",
      },
    },
    subtitle1: {
      fontSize: "1.8rem",
      color: "#4F5C87",
      letterSpacing: "0.56px",
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "1.4rem",
      letterSpacing: "0.16px",
      color: "#4a5767",
      fontWeight: 500,
    },
    body1: {
      fontSize: "1.6rem",
      letterSpacing: "0.44px",
      color: "#636e95",
    },
    body2: {
      fontSize: "1.6rem",
      letterSpacing: "0.5px",
      color: "#636e95",
    },
    numbersLight: {
      fontFamily: "F37 Judge, sans-serif",
      fontWeight: "bold",
      fontSize: "1.8rem",
      color: "#4F5C87",
      letterSpacing: "0.9px",
      lineHeight: "1.67",
    },
    numbersDark: {
      fontFamily: "F37 Judge, sans-serif",
      fontSize: "2.1rem",
      fontWeight: "bold",
      letterSpacing: "0.66px",
      color: "#242f57",
    },
    button: {
      fontSize: "1.4rem",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        ":root": {
          "--product-version": version["product-version"],
        },
        html: {
          [theme.breakpoints.down("mobM")]: {
            fontSize: 9,
          },
          [theme.breakpoints.down("mobS")]: {
            fontSize: 8,
          },
        },
      }),
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up("lg")]: {
            maxWidth: "152rem",
          },
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.MuiDayPicker-weekDayLabel": {
            fontSize: "1.4rem",
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiPickersDay-root": {
            fontSize: "1.4rem",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          fontSize: "1.4rem",
          ".MuiCalendarPicker-root": {
            fontSize: "1.6rem",
            ".MuiIconButton-root": {
              "& svg": {
                width: "2.5rem",
                height: "2.5rem",
              },
            },
            ".MuiPickersArrowSwitcher-root": {
              svg: {
                width: "2.5rem",
                height: "2.5rem",
              },
            },
            ".PrivatePickersFadeTransitionGroup-root": {
              ".MuiYearPicker-root .PrivatePickersYear-root .PrivatePickersYear-yearButton": {
                fontSize: "1.6rem",
              },
              ".MuiTypography-caption": {
                fontSize: "1.4rem",
              },
              ".MuiMonthPicker-root .PrivatePickersMonth-root": {
                fontSize: "1.6rem",
              },
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: "rgba(99,110,149,0.5)",
          fontSize: "1.4rem",
          padding: "13px 20px 9px 20px",
          borderColor: "#9FB0C6",
          backgroundColor: "#FAFCFE",
          textTransform: "capitalize",
          borderRadius: 10,
          "&:focus": {
            color: "#9FB0C6",
            borderColor: "#9FB0C6",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "rgba(99,110,149,0.5)",
          fontSize: "1.4rem",
          borderColor: "#9FB0C6",
          borderRadius: 10,
          "&:focus": {
            color: "#9FB0C6",
            borderColor: "#9FB0C6",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            color: "#7C88B1",
            borderColor: "#9FB0C6",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: 1,
            borderColor: "#9FB0C6",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: "Off",
      },
      styleOverrides: {
        root: {
          color: "rgba(99,110,149,0.5)",
          border: "1px solid #9FB0C6",
          borderWidth: 0,
          borderRadius: 10,
          backgroundColor: "#FAFCFE",
          fontSize: "2.2rem",
          "& .MuiInputBase-root": {
            fontSize: "2.2rem",
          },
          "& .MuiOutlinedInput-input": {
            color: "rgba(99,110,149,0.5)",
            fontSize: "1.4rem",
            padding: "13px 20px 9px 20px",
            "&:focus": {
              color: "#7C88B1",
            },
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D2D5E1",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: "1rem",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: 0.5,
            borderColor: "#7C88B1",
          },
          "& .MuiInputAdornment-root": {
            "& .MuiIconButton-root": {
              "& svg": {
                width: "2rem",
                height: "2rem",
                color: "#7C88B1",
              },
            },
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "outlined",
      },
    },
  },
});

// theme.typography.h3 = {
//     [theme.breakpoints.down('mobL')]: {
//         fontSize: '3rem'
//     }
// }

export default theme;
