export function Logo<T>(props: T) {
  return (
    <svg width="5.5rem" height="3.1rem" viewBox="0 0 55 31" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M88.494 23.989A8.85 8.85 0 0195.035 38.8a8.85 8.85 0 01-6.541 14.811H70.631a2.91 2.91 0 110-5.82h17.863a3.03 3.03 0 100-6.06h-5.383a2.91 2.91 0 01-2.91-2.91v-.042a2.91 2.91 0 012.91-2.91h5.383a3.03 3.03 0 100-6.06H70.631a2.91 2.91 0 110-5.82h17.863zM61.899 47.797a2.877 2.877 0 012.875 2.88v.054c0 1.591-1.287 2.88-2.875 2.88H45.91a2.877 2.877 0 01-2.876-2.88v-.054c0-1.59 1.288-2.88 2.875-2.88H61.9zm12.41-11.904c1.585 0 2.87 1.29 2.87 2.88v.054c0 1.59-1.285 2.88-2.87 2.88H45.904a2.875 2.875 0 01-2.87-2.88v-.054c0-1.59 1.285-2.88 2.87-2.88h28.405zM61.897 23.989a2.877 2.877 0 012.875 2.88v.054c0 1.59-1.287 2.88-2.875 2.88H45.908a2.877 2.877 0 01-2.875-2.88v-.054c0-1.591 1.287-2.88 2.875-2.88h15.99z"
        transform="translate(-43 -23)"
        fill="#fff"
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </svg>
  );
}
