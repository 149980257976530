export function SidebarFoldIcon<T>(props: T) {
  return (
    <svg viewBox="0 0 28 24" width={"2.8rem"} height={"2.4rem"} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14-2.435c.336 0 .609.273.609.609v7.052l2.612-2.613a.61.61 0 01.862.861l-3.652 3.653a.61.61 0 01-.862 0L9.917 3.474a.61.61 0 01.862-.861l2.612 2.613v-7.052c0-.336.273-.609.609-.609m-11.565 14c0-.336.272-.608.608-.608h21.914a.609.609 0 010 1.217H3.043a.609.609 0 01-.608-.609m7.482 8.091l3.652-3.652a.61.61 0 01.862 0l3.652 3.652a.61.61 0 01-.862.862l-2.612-2.614v7.053a.609.609 0 01-1.218 0v-7.053l-2.612 2.614a.61.61 0 01-.862-.862"
        transform="translate(-290 -26) translate(0 -.708) translate(290 27.577) rotate(90 14 11.565)"
        fill="#FFF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
        opacity={0.627415248}
      />
    </svg>
  );
}

export function SidebarUnfoldIcon<T>(props: T) {
  return (
    <svg width="3rem" height="2.4rem" viewBox="0 0 30 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M-10.957 11.565c0-.336.273-.608.61-.608h21.912a.609.609 0 010 1.217h-21.913a.609.609 0 01-.609-.609"
          transform="translate(-34 -27) translate(34.5 27.235) translate(14.098) rotate(90 .609 11.565)"
          fill="#FFF"
          opacity={0.627415248}
        />
        <path
          d="M24.37 6.696c.336 0 .608.272.608.608v7.053l2.613-2.614a.61.61 0 01.862.862L24.8 16.257a.61.61 0 01-.862 0l-3.652-3.652a.61.61 0 01.861-.862l2.613 2.614V7.304c0-.336.273-.609.609-.609"
          fill="#ABAEBC"
          transform="translate(-34 -27) translate(34.5 27.235) rotate(-90 24.37 11.565)"
        />
        <path
          d="M4.87 6.696c.336 0 .608.272.608.608v7.053l2.613-2.614a.61.61 0 01.862.862L5.3 16.257a.61.61 0 01-.862 0L.787 12.605a.61.61 0 01.862-.862l2.612 2.614V7.304c0-.336.273-.609.609-.609"
          fill="#ABAEBC"
          transform="translate(-34 -27) translate(34.5 27.235) matrix(0 -1 -1 0 16.435 16.435)"
        />
      </g>
    </svg>
  );
}

export function DashboardEQIcon<T>(props: T) {
  return (
    <svg width="1.9rem" height="1.8rem" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.5 2.143C2.5.959 3.46 0 4.643 0h13.714C19.541 0 20.5.96 20.5 2.143v13.714c0 1.184-.96 2.143-2.143 2.143H4.643A2.143 2.143 0 012.5 15.857V2.143zM4.643.857c-.71 0-1.286.575-1.286 1.286v13.714c0 .71.576 1.286 1.286 1.286h13.714c.71 0 1.286-.575 1.286-1.286V2.143c0-.71-.576-1.286-1.286-1.286H4.643zM8.07 3.43c.237 0 .429.192.429.428v1.329a2.144 2.144 0 010 4.2v4.757a.429.429 0 01-.857 0V9.386a2.143 2.143 0 010-4.2V3.857c0-.236.192-.428.428-.428zm6.858 0c.236 0 .428.192.428.428v4.757a2.144 2.144 0 010 4.2v1.329a.429.429 0 01-.857 0v-1.329a2.143 2.143 0 010-4.2V3.857c0-.236.192-.428.429-.428zM8.07 6a1.286 1.286 0 100 2.571 1.286 1.286 0 000-2.571zm6.858 3.429a1.286 1.286 0 100 2.57 1.286 1.286 0 000-2.57z"
        transform="translate(-39 -136) translate(37.315 136)"
        fill="#8C92A7"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  );
}
