// Styles
import { HeaderOffset } from "./Header.styles";
//Hooks
import { useAuth } from "./useAuth";
// Utils
import HeaderMobile from "./mobile/HeaderMobile";
import { useBreakpoints } from "../../../utils/hooks/useBreakpoints";
import HeaderDesktop from "./desktop/HeaderDesktop";

type THeader = {
  offset: string;
};

const Header = ({ offset }: THeader) => {
  useAuth();
  const { mobileLarge } = useBreakpoints();

  if (mobileLarge) return <HeaderMobile />;

  return (
    <>
      <HeaderDesktop offset={offset} />
      <HeaderOffset />
    </>
  );
};

export default Header;
