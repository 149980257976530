import { ThemeProvider } from "@mui/material";
import theme from "./styled/theme/theme";
import Main from "./components/layout/main/Main";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import ScrollToTop from "./components/layout/utils/ScrollToTop";
import { Auth0Provider } from "@auth0/auth0-react";
import {useState} from "react";
import { Context404 } from "./utils/context/Context404";
import "swiper/css";
import {SnackbarProvider} from "notistack";


function App() {
  const [is404, setIs404] = useState(false);

  const redirectUri = () => {
    if (typeof window !== 'undefined') {
      return window.location.origin + window.location?.search
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Provider store={store}>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
            audience="https://dev-s5y764on.us.auth0.com/api/v2/"
            scope="read:current_user update:current_user_metadata"
            useRefreshTokens
            redirectUri={redirectUri()}
              // onRedirectCallback={onRedirectCallback}
            // cacheLocation="localstorage"
          >
            <SnackbarProvider
                maxSnack={2}
                hideIconVariant
                autoHideDuration={5000}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                preventDuplicate
                style={{fontSize: "1.4rem"}}
            >
              <ScrollToTop>
                {/*<>{!is404 && <Header />}</>*/}
                <Context404.Provider value={[is404, setIs404]}><Main/></Context404.Provider>
                <CssBaseline/>
              </ScrollToTop>
            </SnackbarProvider>
          </Auth0Provider>
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
