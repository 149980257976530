import {Dialog, IconButton, styled} from "@mui/material";
import theme from "../../../../styled/theme/theme";
import {useBreakpoints} from "../../../../utils/hooks/useBreakpoints";

export const HeaderPlusButton = styled(IconButton)`
  //opacity: 0.21;
  border-radius: 0.8rem;
  border: solid 3px #0080ff;
  font-size: 4rem;
  font-weight: 500;
  width: 4rem;
  height: 4rem;
  color: #0080ff;
  //cursor: not-allowed;
  transform: scale(0.5);

  ${theme.breakpoints.up("mobL")} {
    display: none;
  }
`;

export const ThreeDotsHeader = ({ open, handleOpen }: any) => {
    const { mobileLarge } = useBreakpoints();

    if (!mobileLarge) return null;

    return (
        <IconButton disableRipple onClick={handleOpen} sx={{ display: { xs: "block", mobL: "none" } }}>
    <svg viewBox="0 0 53 9" width={"5.3rem"} height={".9rem"} xmlns="http://www.w3.org/2000/svg">
    <g
        transform="translate(-490 -164) translate(490.183 164.945)"
    fill={open ? "#fff" : "#0080ff"}
    stroke="none"
    strokeWidth={1}
    fillRule="evenodd"
    >
    <circle cx={3.8006293} cy={3.8006293} r={3.8006293} />
    <circle cx={26.4018879} cy={3.8006293} r={3.8006293} />
    <circle cx={49.0031465} cy={3.8006293} r={3.8006293} />
    </g>
    </svg>
    </IconButton>
);
};

export const MobileMenuContainer = styled(Dialog)`
  height: 100vh;
  z-index: 1000;

  .MuiPaper-root {
    max-height: 100vh;
    //width: 100vw;
    margin: 0;
  }

  .MuiDialogContent-root {
    height: 100vh;
    width: 100vw;
    padding-top: 30rem;
    background-color: #242f57;
  }
`;
