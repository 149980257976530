//Core
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//Services
import { checkUserCampaigns } from "../../../services/statisticsService";
//Hooks
import { useBreakpoints } from "../../../utils/hooks/useBreakpoints";
import {userSelector} from "../../../redux/user/userSelector";
//Utils
import {pathWithUTM} from "../../../utils/pathWithUTM";
import {useAuth} from "../header/useAuth";

export const useMain = () => {
  // const [loader, setLoader] = useState(true);
  const [noUser, setNoUser] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const { id, hasCampaigns, hasPRCampaigns, hasDrafts, userData } = useSelector(userSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const {loading, isAuthenticated} = useAuth();
  const { tabletResolutions } = useBreakpoints();

  useEffect(() => {
    if ((hasCampaigns === undefined && hasDrafts === undefined && hasPRCampaigns === undefined) || userData.email === "") return;
    // setLoader(false);
    if (!userData?.has_nft) {
      navigate(pathWithUTM(`/settings`));
    } else {
      if (hasCampaigns && location.pathname === "/") {
        navigate(pathWithUTM("/dashboard"));
      }
    }
  }, [hasCampaigns]);

  useEffect(() => {
    if (!id) return;
    const func = async () => {
      await checkUserCampaigns();
    };
    void func();
  }, [id]);

  useEffect(() => {
    if (!loading && !isAuthenticated) setNoUser(true);
    if (!loading && isAuthenticated) setNoUser(false);
  }, [isAuthenticated, loading]);

  useEffect(() => {
    if (tabletResolutions) {
      setSideBarOpen(false);
    } else {
      setSideBarOpen(true);
    }
  }, [tabletResolutions]);

  return { loading, noUser, sideBarOpen, setSideBarOpen };
};
