//Core
import React, { Dispatch, Fragment, SetStateAction } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
//Styles
import { AccordionSummary, IconButton, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import {
  SidebarAccordion,
  SidebarBody,
  SidebarContainer,
  SidebarDivider,
  SideBarHead,
  SidebarMainMenuItem,
  SidebarPageList,
} from "./Sidebar.styles";
import { FlexBox } from "../../../styled/Layout";
//Images
import { Logo } from "../../../assets/svg/logo";
import { DashboardEQIcon, SidebarFoldIcon, SidebarUnfoldIcon } from "./Sidebar.icons";
//Components
import SidebarMenuItem from "./menuItem/SidebarMenuItem";
import SidebarRewards from "./rewards/SidebarRewards";
//Hooks
import { userSelector } from "../../../redux/user/userSelector";
import { useSidebar } from "./useSidebar";

type TSidebar = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

//TODO: has_nft
const Sidebar = ({ open, setOpen }: TSidebar) => {
  const { hasCampaigns, userData } = useSelector(userSelector);
  // const { has_nft } = userData;
  const has_nft = true;
  const { pathname, search } = useLocation();

  const { sidebarSectionsList } = useSidebar();

  return (
    <SidebarContainer open anchor={"left"} variant={"persistent"} PaperProps={{ sx: { width: open ? "35rem" : "10rem" } }}>
      <SideBarHead>
        {open && (
          <IconButton>
            <Link to={"/" + search} className={hasCampaigns ? "link-disabled" : ""}>
              <Logo />
            </Link>
          </IconButton>
        )}

        <IconButton onClick={() => setOpen(!open)}>{open ? <SidebarFoldIcon /> : <SidebarUnfoldIcon />}</IconButton>
      </SideBarHead>
      <SidebarBody>
        <Link to={"/dashboard" + search} className={!has_nft ? "link-disabled" : ""}>
          <FlexBox columnGap={".6rem"}>
            <DashboardEQIcon />
            {open && <SidebarMainMenuItem>Dashboard</SidebarMainMenuItem>}
          </FlexBox>
        </Link>
        <SidebarDivider className={!open ? "folded" : ""} />
        {sidebarSectionsList.map((sec) => (
          <Fragment key={sec.title}>
            <SidebarMenuItem folded={!open} title={sec.title} titleShort={sec.shortTitle} link={sec.link} disabled={sec.disabled}>
              <SidebarPageList>
                {sec.subSections.map((subSec) => (
                  <ListItem key={subSec.title + subSec.link} className={subSec.disabled ? 'disabled' : ''}>
                    <Link to={subSec.link + search} className={pathname.startsWith(subSec.link) ? "active" : ""}>
                      {open && <ListItemIcon />}
                      <ListItemText>{subSec.title}</ListItemText>
                    </Link>
                  </ListItem>
                ))}
              </SidebarPageList>
            </SidebarMenuItem>
            <SidebarDivider className={!open ? "folded" : ""} />
          </Fragment>
        ))}

        <SidebarAccordion disabled>
          <AccordionSummary>
            <FlexBox>
              <SidebarMainMenuItem>{open ? "Influencer Marketing" : "IM"}</SidebarMainMenuItem>
              {open && <IconButton className={"plus"}>+</IconButton>}
            </FlexBox>
          </AccordionSummary>
        </SidebarAccordion>

        {open && <SidebarRewards />}
      </SidebarBody>
    </SidebarContainer>
  );
};

export default Sidebar;
