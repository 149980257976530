import { HeaderBalance } from "../../Header.styles";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {pathWithUTM} from "../../../../../utils/pathWithUTM";

type TProps = {
  currency: string;
  balance: number;
};

const BalanceMenu = ({ currency, balance }: TProps) => {
  const navigate = useNavigate();

  return (
    <HeaderBalance>
      <Stack>
        <Typography variant={"h5"}>Balance</Typography>
        <Typography>
          {currency}
          {balance}
        </Typography>
      </Stack>
      <Button startIcon={<span>+</span>} onClick={() => navigate(pathWithUTM("/billing/deposit_withdraw"))}>
        Add funds
      </Button>
    </HeaderBalance>
  );
};

export default BalanceMenu;
