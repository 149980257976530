import { useState } from "react";
import {useSelector} from "react-redux";

import {userSelector} from "../../../../../redux/user/userSelector";

export const useUserMenu = () => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const { userData } = useSelector(userSelector);

  const userName = userData.first_name.length || userData.last_name.length ? `${userData.first_name} ${userData.last_name}` : " ";

  return { userMenuOpen, setUserMenuOpen, userName };
};
