//Core
import React from "react";
import {Link} from "react-router-dom";
//Styles
import { HeaderUserData } from "../../Header.styles";
import { HeaderUserDropdownMenu, HeaderUserMenu } from "./UserMenu.styles";
import { Avatar, Box, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
//Images
import { ArrowDownIcon } from "../../../../../assets/svg/icons";
//Hooks
import { useAuth0 } from "@auth0/auth0-react";
import { useUserMenu } from "./useUserMenu";
//Utils
import {pathWithUTM} from "../../../../../utils/pathWithUTM";

type TProps = {
  pages: { title: string; link: string; disabled?: boolean }[];
};

const UserMenu = ({ pages }: TProps) => {
  const { loginWithPopup, logout, user, isAuthenticated } = useAuth0();
  const { userMenuOpen, setUserMenuOpen, userName } = useUserMenu();

  return (
    <HeaderUserMenu onClick={() => setUserMenuOpen(!userMenuOpen)}>
      <HeaderUserData>
        <Avatar src={user?.picture} />
        <Stack>
          <Typography variant="h4">{userName}</Typography>
          <Typography>Admin</Typography>
        </Stack>
        <ArrowDownIcon className={`arrow ${userMenuOpen ? "open" : ""}`} />
        <HeaderUserDropdownMenu className={!userMenuOpen ? "hidden" : ""}>
          {pages.map((page) => (
            <ListItem key={page.link}>
              <Link to={pathWithUTM(page.link)} className={page.disabled ? "link-disabled" : ""}>
                <ListItemIcon>
                  <Box />
                </ListItemIcon>
                <ListItemText>{page.title}</ListItemText>
              </Link>
            </ListItem>
          ))}

          <ListItem>
            {isAuthenticated ? (
              <div className={"authBtn"} onClick={() => logout({ returnTo: pathWithUTM(`${window.location.origin}/login`) })}>
                <ListItemIcon>
                  <Box />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </div>
            ) : (
              <div className={"authBtn"} onClick={loginWithPopup}>
                <ListItemIcon>
                  <Box />
                </ListItemIcon>
                <ListItemText>Login</ListItemText>
              </div>
            )}
          </ListItem>
        </HeaderUserDropdownMenu>
      </HeaderUserData>
    </HeaderUserMenu>
  );
};

export default UserMenu;
