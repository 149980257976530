import { styled, Box, Typography } from "@mui/material";
import theme from "./theme/theme";
import { mobileScreen } from "../utils/breakpoints";

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const FlexSpaceBetween = styled(FlexBox)`
  justify-content: space-between;
  width: 100%;
`;

export const BlockContainer = styled(Box)`
  position: relative;
  border-radius: 1rem;
  //box-shadow: 0 2px 4px 0 rgba(28, 41, 90, 0.04);
  box-shadow: 0 1.5rem 1.8rem 0 rgba(28, 41, 90, 0.14);
  //border: solid 1px #eaedf7;
  background-color: #fff;
  padding: 3.5rem 5.5rem;
  height: 100%;

  ${theme.breakpoints.down("mobL")} {
    border: solid 1px #d2d6e3;
    box-shadow: 0 1.3rem 2.3rem 0 rgba(0, 0, 0, 0.08);
    border-radius: 2.5rem;
    min-height: 50vh;
    padding: 4rem 3.5rem;
    margin-bottom: 1rem;
  }
`;

export const Separator = styled(Box)`
  flex: 1;
  margin: 1rem;
  height: 1px;
  width: 100%;
  background: repeating-linear-gradient(to right, transparent, transparent 3px, #d8dbe5 5px, #d8dbe5 10px);

  ${theme.breakpoints.down("mobL")} {
    margin: auto 0.3rem 0;
    margin-bottom: 0.7rem;
  }
`;
export const ImgBox = styled(Box)`
  & svg,
  img {
    width: 100%;
    height: 100%;
  }
`;

export const PageTitle = styled(Typography)`
  font-family: "Hellix-Medium", "Hellix", sans-serif;
  font-size: 2.94rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.33px;
  color: #5d678d;
`;

export const CampaignTitleBlock = styled(FlexBox)`
  width: 90%;
  margin: 5rem auto;

  .MuiAvatar-root {
    margin-right: 1rem;
  }

  h3 {
    font-size: 2.3rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-transform: none;
    letter-spacing: 0.25px;
    color: #636e95;
  }
  p {
    font-size: 1.5rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;
    color: #b0b3c1;
  }
`;

export const CampaignProgressBlockMob = styled(Box)`
  background-color: rgba(0, 0, 0, 0.04);
  padding: 5rem 3rem;
  margin-bottom: 5rem;

  > div {
    &:last-of-type {
      margin-top: 2rem;
      width: 100%;
      border: none;
      min-height: unset;
      background-color: transparent;
      box-shadow: none;

      .MuiTypography-body2 {
        font-size: 1.5rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.17px;
        color: #636e95;
      }

      .MuiTypography-numbersLight {
        font-family: "F37 Judge", sans-serif;
        font-size: 1.8rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: 0.9px;
        text-align: right;
        color: #636e95;
      }
    }
  }

  .MuiButtonBase-root {
    display: block;
    width: 90%;
    margin: 0 auto;
    padding: 1.5rem;
    border-radius: 3.3rem;
    border: solid 2px #0080ff;
    background-color: #fff;

    font-size: 2.34rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.26px;
    text-align: center;
    color: #0080ff;
  }
`;

export const SectionHeadBackground = styled(Box)`
  background-color: #f4f7fc;

  ${mobileScreen} {
    background: none;
    padding-top: 5rem;
  }
`;

export const SectionHeadContainer = styled(FlexBox)`
  width: 90%;
  margin: 0 auto;
  padding: 6rem 0;

  h2 {
    font-size: 2.8rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.31px;
    color: #091b32;
    margin-right: 7rem;
    //white-space: nowrap;

    ${mobileScreen} {
      font-size: 2.94rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.33px;
      color: #5d678d;
    }
  }

  ${mobileScreen} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CampaignPageTitle = styled(Typography)`
  &.MuiTypography-h2 {
    font-size: 2.6rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.5px;
    color: #242f57;

    > span {
      font-size: 2rem;
      font-weight: 300;
      letter-spacing: 0.22px;
      color: #091b32;
      
      ${mobileScreen}{
        display: none;
      }
    }
  }
`;

export const MobileBasicStats = styled(FlexBox)`
  justify-content: space-between;
  border-radius: 1.5rem;
  background-color: #00213a;
  padding: 2rem;
  h5 {
    font-family: 'F37 Judge', sans-serif;
    font-size: 1.73rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.54px;
    text-align: left;
    color: #fff;
    opacity: .74;
  }

  p {
    font-family: 'F37 Judge', sans-serif;
    font-size: 3.04rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.95px;
    text-align: left;
    color: #fff;

    span{
      font-size: 1.7rem;
      font-weight: normal;
    }
  }
`;
