import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TSnackSlice } from "../../types/local/notificationTypes";

const initialState: TSnackSlice = {
  notifications: [],
};

export const addErrorSnack = createAsyncThunk(
  "snack/addErrorSnack",
  async (payload: string | { message: string; invalidToken?: boolean }, { dispatch }): Promise<any> => {
    if (typeof payload === "object") {
      // payload.invalidToken && setTokenMissing();
      return payload.message;
    }
    return payload;
  }
);

const snackSlice = createSlice({
  name: "snack",
  initialState,
  reducers: {
    addInfoSnack: (slice, { payload }) => {
      slice.notifications = [...slice.notifications, { key: payload, message: payload, variant: "info" }];
    },
    addSuccessfulSnack: (slice, { payload }) => {
      slice.notifications = [...slice.notifications, { key: payload, message: payload, variant: "success" }];
    },
    removeSnack: (slice, { payload: id }) => {
      slice.notifications = slice.notifications.filter((note) => note.key !== id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addErrorSnack.fulfilled, (slice, { payload }) => {
      slice.notifications = [...slice.notifications, { key: payload, message: payload, variant: "error" }];
    });
  },
});

export default snackSlice.reducer;
export const { removeSnack, addInfoSnack, addSuccessfulSnack } = snackSlice.actions;
