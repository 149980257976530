//Core
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useLocation } from "react-router-dom";
//Styles
import { DialogContent, ListItem, Stack, Typography } from "@mui/material";
//Images
import { TrianglesIcon } from "../../../../../assets/svg/icons";
//Components
import MobileMenuItem from "./item/MobileMenuItem";
//Hooks
import { useHeaderMobileMenu } from "./useHeaderMobileMenu";

const HeaderMobileMenu = ({ pages, toggleMobileMenu }: any) => {
  const { logout } = useAuth0();
  const { pathname, search } = useLocation();
  const { accRef,campaignTypes } = useHeaderMobileMenu();

  return (
    <DialogContent>
      <Stack className={"mobileOnly"} alignItems={"center"} mt={"5rem"}>
        <MobileMenuItem title={"Account"} ref={accRef}>
          <ListItem className={pathname === "/dashboard" ? "active" : ""}>
            <Link to={"/dashboard" + search} onClick={() => toggleMobileMenu(false)}>
              <Typography>{pathname.startsWith("/dashboard") && <TrianglesIcon />}Dashboard</Typography>
            </Link>
          </ListItem>
          {pages?.map((page: any) => (
            <ListItem key={page?.link} className={pathname.startsWith(page.link) ? "active" : ""}>
              <Link to={page?.link + search} onClick={() => toggleMobileMenu(false)}>
                <Typography>
                  {pathname.startsWith(page.link) && <TrianglesIcon />} {page?.title}
                </Typography>
              </Link>
            </ListItem>
          ))}
          <ListItem>
            <Typography onClick={() => logout({ returnTo: `${window.location.origin}/login` + search })}>Logout</Typography>
          </ListItem>
        </MobileMenuItem>
        {campaignTypes.map((camp) => (
          <MobileMenuItem key={camp.title} title={camp.title} ref={camp.ref}>
            {camp.pages.map((page) => (
              <ListItem key={page.route} className={page.route === pathname ? "active" : ""}>
                <Link to={page.route + search} onClick={() => toggleMobileMenu(false)}>
                  <Typography>
                    {page.route === pathname && <TrianglesIcon />}
                    {page.title}
                  </Typography>
                </Link>
              </ListItem>
            ))}
          </MobileMenuItem>
        ))}
        <MobileMenuItem title={"Influencer Marketing"} disabled />
      </Stack>
    </DialogContent>
  );
};

export default HeaderMobileMenu;
