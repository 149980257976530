import { createSlice } from "@reduxjs/toolkit";
import {
  TAdData, TAdsStatusList,
  TComparisonOverviewData,
  TOverviewCampaignData,
  TOverviewChannelData,
  TTotalInfo,
} from "../../types/reduxData/overviewTypes";
import { TGadget } from "../../types/reduxData/dataTypes";
import {PRTotalInfo} from "../../types/services/statistics/response/adData/adDataResponse";

type TInitialState = {
  total_info: TTotalInfo | undefined;
  ads_list: TAdData[] | undefined;
  info_by_campaigns: { [key: string]: TOverviewCampaignData } | undefined;
  // countries: TGadget[] | undefined;
  // devices: TGadget[] | undefined;
  // websites: TGadget[] | undefined;
  info_by_channels: { [key: string]: TOverviewChannelData } | undefined;
  comparison: TComparisonOverviewData | undefined;
  ads_by_status: TAdsStatusList | undefined;
  pr_info: PRTotalInfo | undefined;
};

const initialState: TInitialState = {
  total_info: undefined,
  ads_list: undefined,
  info_by_campaigns: undefined,
  // countries: undefined,
  // devices: undefined,
  // websites: undefined,
  info_by_channels: undefined,
  comparison: undefined,
  ads_by_status: undefined,
  pr_info: undefined
};

const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    setOverviewData: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const { setOverviewData } = overviewSlice.actions;

export default overviewSlice.reducer;
