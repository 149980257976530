import {List, styled} from "@mui/material";
import {FlexBox} from "../../../../../styled/Layout";

export const HeaderUserMenu = styled(FlexBox)`
  position: relative;
  border-radius: 0.7rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px rgba(151, 151, 151, 0.12);
  padding: 1rem;
`;

export const HeaderUserDropdownMenu = styled(List)`
  position: absolute;
  top: 90%;
  left: -0.5%;
  background-color: #fff;
  width: 101%;
  padding: 3rem 0 3rem 2.5rem;
  cursor: default;

  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px rgba(151, 151, 151, 0.12);
  border-top: none;
  border-radius: 0 0 0.7rem 0.7rem;

  transition: 0.3s;
  transform-origin: top;
  &.hidden {
    transform: scaleY(0);
    opacity: 0;
  }

  .MuiListItemIcon-root {
    min-width: auto;
    width: 0.6rem;
    height: 0.6rem;
    border: 1px solid #979797;
    border-radius: 50%;
    margin-right: 0.8rem;
  }

  a {
    display: flex;
    align-items: center;
  }

  .MuiListItemText-root {
    font-size: 1.3rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    color: #091b32;
  }

  li {
    a,
    .authBtn {
      opacity: 0.48;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    .authBtn {
      display: flex;
      align-items: center;
    }
    //margin-bottom: .5rem;
  }
`;
