//Core
import React from "react";
import {Link, useLocation} from "react-router-dom";
//Styles
import { SidebarAccordion, SidebarMainMenuItem } from "../Sidebar.styles";
import { AccordionDetails, AccordionSummary, IconButton } from "@mui/material";
import { FlexBox } from "../../../../styled/Layout";

type TSidebarMenuItem = {
  folded: boolean;
  title: string;
  titleShort: string;
  link: string;
  disabled?: boolean;
  children: any;
};
const SidebarMenuItem = ({ folded, title, titleShort, link, disabled, children }: TSidebarMenuItem) => {
    const {search} = useLocation();

  if (folded)
    return (
      <SidebarMainMenuItem>
        <Link to={link + search}> {titleShort} </Link>
      </SidebarMainMenuItem>
    );

  return (
    <SidebarAccordion disabled={disabled}>
      <AccordionSummary>
        <FlexBox>
          <SidebarMainMenuItem>{title}</SidebarMainMenuItem>
          {!folded && (
            <>
              <IconButton className={"plus"}>+</IconButton>
              <IconButton className={"minus"}>-</IconButton>
            </>
          )}
        </FlexBox>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </SidebarAccordion>
  );
};

export default SidebarMenuItem;
