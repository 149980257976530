import { useAuth0 } from "@auth0/auth0-react";
import {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { setUserField, setUserId } from "../../../redux/user/userSlice";
import { useNavigate } from "react-router-dom";
import { getAvailableBalance, getUser } from "../../../services/userService";
import {prClient} from "../../../services/prService";
import {pathWithUTM} from "../../../utils/pathWithUTM";

export const useAuth = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Connected if already authorized in another project
  useEffect(() => {
    if (!isLoading) {
      const getToken = async () => {
        try {
          const token = await getAccessTokenSilently();
          prClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        } catch (err: any) {
          console.log(err?.message);
            navigate(pathWithUTM("/login"));
        }
        finally {
            setLoading(false);
        }
      };
      void getToken();
    }
  }, [isAuthenticated, isLoading]);

  const getUserWithLoader = async () => {
    if (user) {
      dispatch(setUserField({ isLoadingUser: true }));
      dispatch(setUserId(user.sub as string));
      const userData = await getUser(user.sub as string);
      // if (!userData?.payment_info?.has_nft) navigate(pathWithUTM("settings"));
      await getAvailableBalance();
      dispatch(setUserField({ isLoadingUser: false }));
    }
  };
  useEffect(() => {
    void getUserWithLoader();
  }, [user]);

  return {loading, isAuthenticated, user}
};
