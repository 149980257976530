import { useSelector } from "react-redux";
import { userSelector } from "../../../redux/user/userSelector";

export const useSidebar = () => {
  const {
    hasCampaigns,
    // userData,
    hasDrafts,
    hasPRCampaigns
  } = useSelector(userSelector);

  // TMP NFT CHECK DISABLED //
  // const { has_nft } = userData;
  const has_nft = true;

  const sidebarSectionsList = [
    {
      title: "PPC Ads",
      shortTitle: "PPC",
      link: has_nft && hasCampaigns ? "/ppc/overview" : "#",
      disabled: false,
      subSections: [
        { title: "Overview", link: has_nft && hasCampaigns ? "/ppc/overview" : "#", disabled: !hasCampaigns },
        { title: "My campaigns", link: has_nft && (hasCampaigns || hasDrafts) ? "/ppc/mycampaigns" : "#", disabled: !hasCampaigns && !hasDrafts },
      ],
    },
    {
      title: "PR Campaigns",
      shortTitle: "PR",
      link: has_nft && hasPRCampaigns ? "/pr/overview" : "#",
      disabled: false,
      subSections: [
        { title: "Overview", link: has_nft && hasPRCampaigns ? "/pr/overview" : "#", disabled: !hasPRCampaigns },
        { title: "My campaigns", link: has_nft && hasPRCampaigns ? "/pr/mycampaigns" : "#", disabled: !hasPRCampaigns },
      ],
    },
    {
      title: "Data Mining",
      shortTitle: "DM",
      link: "/data_mining/overview",
      disabled: false,
      subSections: [
        { title: "Overview", link: "/data_mining/overview", disabled: false },
        { title: "Pixel", link: "/data_mining/pixel", disabled: false },
      ],
    },
  ];

  return { sidebarSectionsList };
};
