import { Stack, styled } from "@mui/material";

export const SidebarRewardsContainer = styled(Stack)`
  border-radius: 1.5rem;
  background-color: #fff;
  margin-top: 10rem;
  overflow: hidden;

  h3 {
    padding: 2.4rem 2.4rem 0 2.4rem;
  }
`;

export const SidebarRewardsDataList = styled(Stack)`
  > div {
    padding: 2.4rem;

    &:nth-of-type(even) {
      background-color: #f4f4f4;
    }
  }

  h5 {
    font-size: 1.12rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: left;
    color: #000;
    margin-bottom: 0.3rem;
  }

  p {
    font-family: "F37 Judge", sans-serif;
    font-size: 1.4rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #000;
    b {
      font-size: 2.59rem;
      font-weight: bold;
      letter-spacing: 1.3px;
    }

    span {
      font-size: 2.59rem;
      margin: 0 1rem;
    }
  }
`;

export const RewardsDistribution = styled(Stack)`
  background-color: #708397;
  padding: 2.4rem;

  h3 {
    text-align: left;
    color: #fff;
    margin-bottom: 1.4rem;
    padding: 0;
  }

  p {
    font-size: 1.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.38px;
    text-align: left;
    color: #fff;
    margin-bottom: 3.1rem;
  }

  button {
    font-family: "F37 Judge", sans-serif;
    font-size: 1.42rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.71px;
    text-align: right;
    color: #fff;

    border-color: #fff;
    padding: 1rem;
    border-radius: 0.8rem;
    margin-bottom: 0.6rem;
    
    cursor: default;

    &:hover {
      border-color: #fff;
    }
  }
`;
