import {styled} from "@mui/material";
import theme from "../../../../styled/theme/theme";

export const CreateCampaignLink = styled("a")`
  background-color: #0080ff;
  //display: flex;
  //align-items: center;
  //opacity: 1 !important;
  border-radius: 0.8rem;

  &.disabled {
    pointer-events: none;
    cursor: default;
    background-color: rgba(160, 160, 160, 0.1);
  }

  p {
    font-size: 1.7rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.53px;
    color: #fff;
    padding: 1.35rem 4.75rem;
  }

  //@media only screen and (max-width: 1650px) {
  //  padding: 0 1.5rem;
  //
  //  p {
  //    font-size: 1.8rem;
  //  }
  //}

  // ${theme.breakpoints.down("xl")} {
  //   padding: 0 1rem 0;
  //   margin-left: 1.5vw;
  //   flex-shrink: 1 !important;
  //   min-width: 10vw;
  //
  //   p {
  //     font-size: 1vw;
  //     white-space: unset;
  //   }
  // }
`;
