import { useState } from "react";

export const useHeaderMobile = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return { mobileMenuOpen, toggleMobileMenu };
};
