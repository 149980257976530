//Core
import React, {FC} from "react";
import auth0 from "auth0-js";
//Styles
import {LoginDescription, LoginFormStack, LoginTitle} from "../Login.styles"
//Data
import {loginDescriptions, TActivePage, TActivePassword, titles} from "../Login.data";
//Hooks
import {useForgotPassword} from "./useForgotPassword";
import {ForgotPasswordForm} from "./content/ForgotPasswordForm";
import {RequestSent} from "./content/RequestSent";

type TProps = {
    webAuth: auth0.WebAuth;
    activePage: TActivePassword;
    changePage: (key: TActivePage) => void;
}

export const ForgotPassword: FC<TProps> = ({webAuth, activePage, changePage}) => {
    const {formData, email, sendForm, errorResponse} = useForgotPassword(webAuth, changePage);
    return (
        <LoginFormStack>
            <LoginTitle>{titles[activePage]}</LoginTitle>
            <LoginDescription>{loginDescriptions[activePage](email)}</LoginDescription>
            {activePage === 'forgotPassword' &&
                <ForgotPasswordForm formData={formData} sendForm={sendForm} errorResponse={errorResponse}/>
            }
            {activePage === 'requestSent' && <RequestSent email={email}/>}
        </LoginFormStack>
    )
}