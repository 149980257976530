//Core
import {Formik} from "formik";
import React, {FC} from "react";
//Styles
import {InputAdornment} from "@mui/material";
import {LoginButton} from "../../Login.styles";
import {FormInput, FormInputName} from "../../../../../styled/Forms.styles";
//Hooks
import {TResetFormData, validationSchema} from "../useForgotPassword";
//Images
import {EmailSvg} from "../../img/logos";

type TProps = {
    sendForm: (values: TResetFormData) => Promise<void>,
    errorResponse: string | null;
    formData: TResetFormData;
}

export const ForgotPasswordForm: FC<TProps> = ({formData, sendForm, errorResponse}) => {
    return (
        <Formik
            initialValues={formData}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                await sendForm(values);
            }}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
              }) => (
                <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                    <FormInput
                        placeholder={"Email"}
                        name={"username"}
                        type={"email"}
                        autoComplete={"email"}
                        className={'login'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.username}
                        error={touched.username && Boolean(errors.username)}
                        helperText={errors.username && touched.username && errors.username}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{<EmailSvg/>}</InputAdornment>,
                        }}
                    />
                    <LoginButton variant={"contained"}
                                 color={"secondary"}
                                 type={"submit"}
                                 sx={{marginTop: '1rem'}}
                                 loading={isSubmitting}
                                 disabled={isSubmitting || !!errors.username}
                    >
                        {'Reset'}
                    </LoginButton>
                    {errorResponse && <FormInputName className={'error'}>{errorResponse}</FormInputName>}
                </form>
            )}
        </Formik>
    )
}