import { AppBar, styled } from "@mui/material";
import { Box } from "@mui/system";
import { FlexBox } from "../../../styled/Layout";
import theme from "../../../styled/theme/theme";
import { mobileScreen } from "../../../utils/breakpoints";

export const HeaderContainer = styled(AppBar)`
  background-color: #fff;
  padding: 0.5rem 1.5rem;
  transition: 0.3s;

  .MuiToolbar-root {
    justify-content: space-between;
  }
  > div > div > a {
    flex-shrink: 0;
  }

  ${theme.breakpoints.down("mobL")} {
    background-color: #fff;

    &.mobOpen {
      background-color: #242f57;
    }

    .logo {
      svg {
        path {
          fill: #0080fe;
        }
      }
    }
  }
`;

export const HeaderBalance = styled(FlexBox)`
  border-radius: 0.7rem;
  border: solid 1px #e9f3fd;
  padding: 1rem 1.4rem;

  ${mobileScreen} {
    width: 100vw;
    background-color: #051347;
    border: none;
    flex-direction: column;
    margin-top: 3rem;
    margin-left: -1.5rem;
    padding: 3.4rem;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.41);
    border-radius: 0;
  }
  h5 {
    font-size: 0.7rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.22px;
    color: #636e95;

    ${mobileScreen} {
      font-size: 1.54rem;
      color: #fff;
      opacity: 0.69;
    }
  }

  p {
    font-size: 1.9rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.59px;
    color: #636e95;

    ${mobileScreen} {
      color: #fff;
      font-size: 4.18rem;
    }
  }

  button {
    display: block;
    //height: 100%;
    //margin-top: -2.5rem;
    border: none;
    border-radius: 1.1rem;
    padding: 1.6rem 2rem;
    margin: -1rem -1.4rem -1rem 1.2rem;

    font-size: 1.02rem;
    font-weight: 600;
    text-transform: none;
    letter-spacing: 0.32px;
    color: #0080ff;
    border-radius: 1.1rem 0.7rem 0.7rem 1.1rem;
    background-color: #e9f3fd;
    display: flex;

    ${mobileScreen} {
      margin: 0;
      background: none;
      font-size: 2rem;
    }

    &:hover {
      border: none;
    }

    .MuiButton-startIcon {
      background-color: #0080ff;
      border-radius: 50%;
      width: 1.6rem;
      height: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.8rem;

      ${mobileScreen} {
        width: 3.2rem;
        height: 3.2rem;
      }

      > span {
        color: #fff;
        font-size: 1.52rem;

        ${mobileScreen} {
          font-size: 3rem;
        }
      }
    }
  }
`;

export const HeaderUserData = styled(FlexBox)`
  //margin-left: 1rem;
  //margin-right: 2.7rem;
  min-width: 19rem;
  cursor: pointer;

  .MuiAvatar-root {
    width: 2.9rem;
    height: 2.9rem;
    margin-right: 0.8rem;
  }
  h4 {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.38px;
    color: #242f57;
  }
  p {
    font-size: 0.8rem;
    letter-spacing: 0.25px;
    color: #242f57;
    text-transform: uppercase;
    opacity: 0.5;
  }

  .arrow {
    margin-left: auto;
    width: 1.2rem;
    transition: 0.3s;

    &.open {
      transform: rotate(180deg);
    }
  }

  ${theme.breakpoints.down("mobL")} {
    align-items: center;
    margin: 2rem 0;

    .MuiAvatar-root {
      width: 5.2rem;
      height: 5.2rem;
      margin-right: 1.2rem;
    }

    h4 {
      font-size: 2.16rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.67px;
      color: #fff;
    }
    p {
      opacity: 0.6;
      font-family: Hellix;
      font-size: 1.44rem;
      letter-spacing: 0.45px;
      color: #fff;
    }
  }
`;

export const HeaderOffset = styled(Box)`
  height: 7.6rem;

  ${theme.breakpoints.down("mobL")} {
    display: none;
  }
`;
