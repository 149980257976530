import auth0 from "auth0-js";
import {useMemo, useState} from "react";
import {TActivePage} from "./Login.data";

export const DATABASE_CONNECTION = 'Username-Password-Authentication';

export const useLogin = () => {
    const [activePage, setActivePage] = useState<TActivePage>("signIn");
    const urlParams = new URLSearchParams(window?.location?.search);
    const redirectUri = urlParams.get('redirect_uri') || window?.location?.origin;
    const webAuth = useMemo(() => new auth0.WebAuth({
            domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
            clientID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
            audience: "https://dev-s5y764on.us.auth0.com/api/v2/",
            redirectUri: redirectUri,
            responseType: 'code',
            scope: 'openid profile email read:current_user update:current_user_metadata offline_access',
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }), []);


    // const forgotPassword = useCallback((data: TFormData) => {
    //     console.log(data.username)
    // }, [webAuth])

    const handleActivePage = () => {
        const data: {[key in TActivePage]: TActivePage} = {
            signIn: 'signUp',
            signUp: 'signIn',
            forgotPassword: 'signIn',
            requestSent: 'signIn',
        }
        setActivePage(data[activePage]);
    }

    return {
        webAuth,
        activePage,
        setActivePage,
        handleActivePage
    }
}