import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appSlice from "./app/appSlice";
import campaignsSlice from "./campaigns/campaignsSlice";
import filtersSlice from "./filters/filtersSlice";
import overviewSlice from "./overview/overviewSlice";
import userSlice from "./user/userSlice";
import snackSlice from "./snack/snackSlice";
import {useDispatch} from "react-redux";

const rootReducer = combineReducers({
  app: appSlice,
  user: userSlice,
  overview: overviewSlice,
  campaigns: campaignsSlice,
  filters: filtersSlice,
  snack: snackSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppState = ReturnType<typeof rootReducer>;
