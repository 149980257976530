//Core
import { AxiosResponse } from "axios";
//Services
import clientBuilder from "./clientBuilder";
//Redux
import { store } from "../redux/store";
//Types
import { PRCampaignListResponse } from "../types/services/pr/response/PRCampaignListResponse";
import {ReleaseDate} from "../types/services/pr/response/ReleaseDate";
import {TPRCampaignStats} from "../types/services/pr/response/PRCampaignStats";
//Utils
import {DEV_ONLY, STAGING} from "../utils/constants";

const BASE_URL = `https://gateway-${DEV_ONLY ? "develop-" : STAGING ? "staging-" : ""}dot-winky-apis.ew.r.appspot.com/`;

//TODO: handle errors and add types
export const prClient = clientBuilder(BASE_URL);

export const getPRCampaignList = async () => {
  const args = {
    user_id: store.getState().user.id ?? "",
    statuses: [],
    campaign_type: "pr",
  };
  try {
    const res: AxiosResponse<PRCampaignListResponse[]> | undefined = await prClient.post("/", { path: "campaigns_list_by_uid", args });
    if (res?.status !== 200) return null;
    if (res?.data && Object.keys(res.data).length) return res?.data;
    else return [];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getPRCampaignById = async (campaignId: string) => {
  const args = {
    user_id: store.getState().user.id ?? "",
    campaign_id: campaignId,
    campaign_type: "pr",
  };
  try {
    const res: AxiosResponse = await prClient.post("/", { path: "campaigns_get_campaign", args });
    console.log(res)
    if (res?.status !== 200) return null;
    if (res?.data && Object.keys(res.data).length) return res.data;
    else return null;
  } catch (error) {
    console.log(error);
    return null;
  }

};

export const archivePRCampaign = async (campaignId: string) => {
  const path = "campaigns_archive_campaign";
  const args = {
    user_id: store.getState().user.id ?? "",
    campaign_id: campaignId,
    campaign_type: "pr",
  };

  const res: AxiosResponse = await prClient.post("/", { path, args });

  return res?.data;
};

export const getCampaignListByStatus = async () => {
  const path = "campaigns_list_by_status";

  const args = {
    user_id: store.getState().user.id ?? "",
    statuses: ["published", "waiting", "rejected", "draft"],
    campaign_type: "pr",
  };

  const res: AxiosResponse = await prClient.post("/", { path, args });

  return res?.data;
};

export const updatePRCampaignStatus = async (campaign_id: string, status: string) => {
  const path = "campaigns_change_campaign_status";
  const args = { user_id: store.getState().user.id ?? "", campaign_type: "pr", campaign_id, status };

  const res: AxiosResponse = await prClient.post("/", { path, args });

  return res?.data;
};

export const reschedulePRCampaign = async (campaign_id: string, date: ReleaseDate | null) => {
  const path = "update_campaign_date";
  const args = { user_id: store.getState().user.id ?? "", campaign_type: "pr", campaign_id, date };

  const res: AxiosResponse = await prClient.post("/", { path, args });

  return res?.data;
};

export const getPRCampaignStats = async (campaign_id: string) => {
  const path = "pr_campaign";
  const args = {
    campaign_id,
    campaign_type: "pr",
  };

  const res: AxiosResponse<TPRCampaignStats> | undefined = await prClient.post("/", { path, args });

  return res?.data;
}

export const getRejectedDescription = async (tag: string) => {
  const path = "get_rejected_description";
    const args = {
        title: tag,
    }

    const res: AxiosResponse = await prClient.post("/", { path, args });

    return res?.data;
}
