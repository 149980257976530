import { useLocation } from "react-router-dom";

export const usePathname = () => {
  const location = useLocation();

  const isDashboardPage = location.pathname === "/dashboard";
  const isCampaignsPage = location.pathname.startsWith("/ppc/mycampaigns");
  const isCampaignPage = location.pathname.startsWith("/ppc/mycampaigns/");
  const isPPCPage = location.pathname.startsWith("/ppc");
  const isPRPage = location.pathname.startsWith("/pr");
  const isCampaignsTable = location.pathname === "/ppc/mycampaigns";
  const isPPCOverviewPage = location.pathname === "/ppc/overview";
  const isPRCampaignsTable = location.pathname === "/pr/mycampaigns";
  const isPRCampaign = location.pathname.startsWith("/pr/mycampaigns/");
  const isPROverviewPage = location.pathname === "/pr/overview";
  const isBillingPage = location.pathname.startsWith("/billing");
  const isBillingDetails = location.pathname === "/billing/details";
  const isDepositWithdraw = location.pathname === "/billing/deposit_withdraw";
  const isBillingHistory = location.pathname === "/billing/history";
  const isDataminingPage = location.pathname.startsWith("/data_mining");
  const isDataminingOverview = location.pathname === "/data_mining/overview";
  const isDataminingPixel = location.pathname === "/data_mining/pixel";

  return {
    isDashboardPage,
    isCampaignsTable,
    isCampaignsPage,
    isCampaignPage,
    isBillingPage,
    isBillingDetails,
    isDepositWithdraw,
    isBillingHistory,
    isPPCOverviewPage,
    isDataminingOverview,
    isDataminingPixel,
    isPPCPage,
    isDataminingPage,
    isPROverviewPage,
    isPRCampaignsTable,
    isPRCampaign,
    isPRPage
  };
};
