import { setCampaignData, setCampaignListData, setComparisonData } from "../redux/campaigns/campaignsSlice";
import { setOverviewData } from "../redux/overview/overviewSlice";
import { store } from "../redux/store";
import { setAvailableDates, setDates } from "../redux/filters/filtersSlice";
import { setHasCampaigns } from "../redux/user/userSlice";
import { setFetchError, setLoading } from "../redux/app/appSlice";
import {appLink, DEV_ONLY, STAGING, userId} from "../utils/constants";
import clientBuilder from "./clientBuilder";
import { StatisticsRoute } from "../types/services/statistics/request/statisticsRoute";
import { AdDataRequest } from "../types/services/statistics/request/adDataRequest";
import { AdDataResponse } from "../types/services/statistics/response/adData/adDataResponse";
import { ComparisonParams } from "../types/services/statistics/request/comparisonParams";
import { ComparisonRequest } from "../types/services/statistics/request/comparisonRequest";
import { ComparisonResponse } from "../types/services/statistics/response/comparison/comparisonResponse";
import { CampaignListRequest } from "../types/services/statistics/request/campaignListRequest";
import { CampaignListResponse } from "../types/services/statistics/response/campaignList/campaignListResponse";
import { AxiosResponse } from "axios";
import { CampaignStatsRequest } from "../types/services/statistics/request/campaignStatsRequest";
import { CampaignStatsResponse } from "../types/services/statistics/response/campaignStats/campaignStatsResponse";
import { AdsByStatusRequest } from "../types/services/statistics/request/adsByStatusRequest";
import { AdsByStatusResponse } from "../types/services/statistics/response/adsByStatus/adsByStatusResponse";
import { CheckCampaignsResponse } from "../types/services/statistics/response/checkCampaignsResponse";
import { DatesRange } from "../types/services/shared/datesRange";
import {pathWithUTM} from "../utils/pathWithUTM";

// const userId = () => store.getState().user.id;
// const userId = () => (typeof window !== 'undefined' && window?.origin === "https://dash.web3m.io") ? "dashboard_sample_data" : store.getState().user.id;

const BASE_URL = `https://mvp-statistics-manager-${DEV_ONLY ? "develop-" : STAGING ? "staging-" : ""}dot-winky-apis.ew.r.appspot.com`;

const statisticsClient = clientBuilder(BASE_URL);

export const dashboardRequestFactory = async <TRequest, TResponse>(route: StatisticsRoute, data: TRequest): Promise<TResponse | any> => {
  try {
    store.dispatch(setLoading({ route, loading: true }));
    const response: AxiosResponse<TResponse> = await statisticsClient.post(`/dashboard/${route}`, data);
    store.dispatch(setLoading({ route, loading: false }));
    return response?.data;
  } catch (err: any) {
    console.log(err.message);
    store.dispatch(setFetchError({ route, error: err.message }));
  }
};

export const getListData = async () => {
  const {
    dates: { start, end },
  } = store.getState().filters;

  const data: AdDataRequest = {
    user_id: userId(),
    start_date: start,
    end_date: end,
  };

  const res: AdDataResponse = await dashboardRequestFactory<AdDataRequest, AdDataResponse>("ad_data", data);

  if (res) {
    store.dispatch(setOverviewData(res));
  }
};

export const getComparisonCampaignData = async (data: ComparisonParams) => {
  const { status } = store.getState().filters;
  const body: ComparisonRequest = {
    user_id: userId(),
    start_date: data.start_date ?? null,
    end_date: data.end_date ?? null,
    comparison: data.comparison,
    campaign_id: data.id,
    campaigns_type: Object.entries(status)
      .filter(([_, value]) => value)
      .map(([status, _]) => status),
    channels_list: data.channels,
  };

  const res: ComparisonResponse = await dashboardRequestFactory<ComparisonRequest, ComparisonResponse>("graph_comparison", body);

  if (res) {
    store.dispatch(setComparisonData(res));
  }
};

export const getCampaignList = async () => {
  // const dates = store.getState().filters.dates_available;

  const data = {
    user_id: userId(),
    // start_date: dates.start,
    // end_date: dates.end,
  };

  const res = await dashboardRequestFactory<CampaignListRequest, CampaignListResponse>("campaign_list", data);

  if (res) {
    store.dispatch(setCampaignListData(res.campaigns));
    return res.campaigns;
  } else {
    return null;
  }
};

export const getCampaignData = async (id: string, dates: DatesRange) => {
  // store.dispatch(clea)
  const data: CampaignStatsRequest = {
    user_id: userId(),
    start_date: dates.start,
    end_date: dates.end,
    campaign_id: id,
  };

  const res: CampaignStatsResponse = await dashboardRequestFactory<CampaignStatsRequest, CampaignStatsResponse>("campaign", data);

  if (res) {
    store.dispatch(setCampaignData({ id: id, data: res }));
  }
};

export const checkUserCampaigns = async () => {
  const response: AxiosResponse<CheckCampaignsResponse> = await statisticsClient.get(`/dashboard/${userId()}`);
  if (response?.data?.has_campaigns) {
    store.dispatch(setDates({ start: response.data.date_range.start_date, end: response.data.date_range.end_date }));
    store.dispatch(
      setAvailableDates({
        start: response.data.date_range.start_date,
        end: response.data.date_range.end_date,
      })
    );
  }
  if (response.data && !response.data.has_campaigns && !response.data.has_drafts && !response.data.has_pr) {
    window.location.replace(pathWithUTM(appLink))
  }
  store.dispatch(setHasCampaigns(response.data));

  return response.data.has_campaigns;
};

export const getAdsByStatus = async () => {
  const data: AdsByStatusRequest = {
    user_id: userId(),
  };

  const res: AdsByStatusResponse = await dashboardRequestFactory<AdsByStatusRequest, AdsByStatusResponse>("ad_status_list", data);

  if (res) {
    store.dispatch(setOverviewData({ ads_by_status: res }));
  }
};

// PROBABLY DEPRECATED
export const withdrawBudget = async (campaignId: string, sum: number) => {
  const data = {
    user_id: userId(),
    amount: sum,
    currency: "USD",
    wallet_address: store.getState().user.userData.wallet_address,
  };

  try {
    const response = await statisticsClient.put(`budget/withdraw/${campaignId}`, data);
    return !!response;
  } catch (err) {
    return false;
  }
};
