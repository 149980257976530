import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { currencyList } from "../../../utils/currencyList";
import { getAvailableBalance } from "../../../services/userService";
import {userSelector} from "../../../redux/user/userSelector";

export const useHeaderData = () => {
  const { userData, id, user_balance } = useSelector(userSelector);
  // TMP NFT CHECK DISABLED //
  // const { has_nft } = userData;
  const has_nft = true;

  const [balanceView, setBalance] = useState<number>(0);
  const [currency, setCurrency] = useState<string>("USD");

  const checkBalance = async () => {
    // const response = await getUser(id)
    const response = await getAvailableBalance();
    // console.log(response)
    if (response?.user_balance && response.user_balance !== user_balance) {
      setBalance(response.user_balance);
      // setCurrency(response.budget_info.currency)
    }
  };

  const pages = [
    { title: "General Settings", link: "/settings" },
    { title: "Billing center", link: "/billing", disabled: !has_nft },
    { title: "Support", link: "/support" },
  ];

  useEffect(() => {
    id && checkBalance();
  }, [id]);

  useEffect(() => {
    user_balance && user_balance !== balanceView && setBalance(user_balance);
  }, [user_balance]);

  return {
    pages,
    balance: balanceView,
    currency: currencyList[currency] ?? currency,
  };
};
