// export const DATE_FORMAT = "yyyy-MM-dd";
import { store } from "../redux/store";

export const DATE_FORMAT = "dd/MM/yyyy";
export const DATE_FORMAT_SLASH = "yyyy / MM / dd";
export const DATE_FORMAT_DASH = "yyyy-MM-dd";

// export const MIN_DATE = "January 1, 2015";

export const DEV_ONLY =
  process.env.NODE_ENV === "development" || (typeof window !== "undefined" && window?.origin === "https://dev-dashboard.web3m.io");
export const PROD_ONLY = process.env.NODE_ENV === "production";
// @ts-ignore
export const STAGING =
  process.env.TARGET_ENV === "staging" || (typeof window !== "undefined" && window?.origin === "https://stag-dashboard.web3m.io");

export const storageToken =
  "@@auth0spajs@@::qf5Jo1duOIMoqzem70sDy74oW7dhs6Hp::https://dev-s5y764on.us.auth0.com/api/v2/::openid profile email read:current_user update:current_user_metadata offline_access";

export const appLink = `https://${DEV_ONLY ? "dev" : STAGING ? "stag" : "app"}.web3m.io`;
export const websiteLink = `https://${DEV_ONLY ? "advertisers." : ""}web3m.io`;
export const LinkSignUp = `https://${DEV_ONLY ? "advertisers." : ""}web3m.io/signup`;

export const userId = () =>
  typeof window !== "undefined" && window?.origin === "https://dash.web3m.io" ? "dashboard_sample_data" : store.getState().user.id;

export const isSampleData = typeof window !== "undefined" && window?.origin === "https://dash.web3m.io";

export const PR_LOGO = "https://storage.googleapis.com/studio-image-winky/studio_logo/86b04dc2-4292-400c-bdd1-94aa6aeeacee";
// export const userId = () => 'dashboard_sample_data'
// export const isSampleData = true
